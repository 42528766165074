import { slug } from "github-slugger";

export const humanize = (content: string) => {
  if (!content) return null;

  return content
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
};

export const slugify = (content: string) => {
  if (!content) return null;
  return slug(content);
};

export const stripHtml = (html: string): string => {
  return html.replace(/<[^>]*>/g, '');
}

export const removeLineBreaks = (input: string) => {
  return input.replace(/\\n|\n/g, "");
};

export const decodeLineBreaks = (input: string) => {
  return input.replace(/\\n|\n/g, "<br>");
};

export const escapeLineBreaks = (input: string) => {
  return input.replace(/\\n|\n/g, "\\n");
};

export const unescapeLineBreaks = (input: string) => {
  return input.replace(/\\n/g, "\n");
};

export const convertQuotes = (input: string, quote = "'") => {
  return input.replace(/[\u201C\u201D"\\'"]/g, quote);
};

export const cleanHtml = (html: string | null | undefined) => {
  if (!html) return;
  
  // Remove newlines
  html = html.replace(/\r?\n/g, "");
  
  // Remove empty elements with only &nbsp;
  html = html.replace(/<[^>]+>\s*&nbsp;\s*<\/[^>]+>/gi, "");
  
  // Remove empty nested divs and articles (more aggressive pattern)
  const emptyTagsPattern = /<(div|article)[^>]*>\s*(<(div|article)[^>]*>\s*)*(<\/(div|article)>\s*)*<\/(div|article)>/gi;
  while (emptyTagsPattern.test(html)) {
    html = html.replace(emptyTagsPattern, "");
  }
  
  // Clean remaining tags
  const tags = html.match(/(<\/?[\S][^>]*>)/gi);
  if (!tags || tags.length === 0) return html;
  
  tags.forEach((tag: string) => {
    const noClass = tag.replace(/(class=".*?")|(class='.*?')|(class=[^\s>]*)/gi, "");
    const noID = noClass.replace(/(id=".*?")|(id='.*?')|(id=[^\s>]*)/gi, "");
    const noData = noID.replace(/(data-.+?=".*?")|(data-.+?='.*?')|(data-[a-zA-Z0-9-]+)/gi, "");
    const noDir = noData.replace(/(dir=".*?")|(dir='.*?')|(dir=[^\s>]*)/gi, "");
    if (html) html = html.replace(tag, noDir);
  });

  return html;
};
